import React from 'react';
import '../styles/global.scss';

const Writing = () => (
  <div className="flex" id="writing">
    <h3 data-sal="slide-up" data-sal-delay="300" data-sal-easing="ease" className="sectionTitle">
      <b>Featured Writing</b>
    </h3>
    <p data-sal="slide-up" data-sal-delay="300" data-sal-easing="ease">
      <a
        href="https://www.kernelmag.io/2/mental-health-apps/"
        target="_blank"
        rel="noreferrer"
      >
        Mental Health Apps Won't Solve the Mental Health Crisis
      </a>{' '}
      for<em> Kernel Magazine</em>
    </p>
    <p data-sal="slide-up" data-sal-delay="300" data-sal-easing="ease">
      <a
        href="https://objectivejournalism.org/2022/01/gig-workers-are-tech-workers-mainstream-tech-journalism-doesnt-reflect-that/"
        target="_blank"
        rel="noreferrer"
      >
        Tech journalism must center the workers that keep Big Tech running
      </a>{' '}
      for<em> The Objective</em>
    </p>
    <p data-sal="slide-up" data-sal-delay="300" data-sal-easing="ease">
      <a href="https://www.thenation.com/article/these-muslim-students-built-an-app-to-document-islamaphobia-on-campus/">
        These Muslim Students Built an App to Document Islamophobia on Campus&nbsp;{' '}
      </a>
      for <em>The Nation</em>
    </p>
    <p data-sal="slide-up" data-sal-delay="300" data-sal-easing="ease">
      <a
        href="http://www.dailytarheel.com/article/2016/10/dogspotting-at-unc-facebook-group-brings-people-together-through-dogs"
        target="_blank"
        rel="noreferrer"
      >
        &lsquo;Dogspotting&rsquo; Facebook group brings UNC together through love of dogs
      </a>{' '}
      for<em> The Daily Tar Heel</em>
    </p>
    <p data-sal="slide-up" data-sal-delay="300" data-sal-easing="ease">
      <a
        href="http://www.guilfordian.com/worldnation/2014/11/07/north-carolina-compensates-victims-of-eugenic-sterilization/#sthash.bP8qHG1Y.dpuf"
        target="_blank"
        rel="noreferrer"
      >
        North Carolina Compensates Victims of Eugenic Sterilization
      </a>
      &nbsp;for&nbsp;<em>The Guilfordian</em>&nbsp;(this piece won a gold key from the Scholastic
      Art &amp; Writing Awards in 2015)
    </p>
    <p data-sal="slide-up" data-sal-delay="300" data-sal-easing="ease">
      Contributed to{' '}
      <a
        href="https://www.pastemagazine.com/articles/2016/06/25-best-songs-of-2016-so-far.html?p=3"
        target="_blank"
        rel="noreferrer"
      >
        The Best Songs of 2016 (So Far){' '}
      </a>
      for <em>Paste Magazine</em> (I worked on #22, 15, and 5)
    </p>
    <p data-sal="slide-up" data-sal-delay="300" data-sal-easing="ease">
      <a
        href="http://www.buzzfeed.com/spoonuniversity/i-hate-salad#.wxrOgw5Wq"
        target="_blank"
        rel="noreferrer"
      >
        41 Healthy Things To Eat That Are Not Salad
      </a>
      &nbsp;for&nbsp;<em>BuzzFeed</em>
    </p>
    <p data-sal="slide-up" data-sal-delay="300" data-sal-easing="ease">
      <a
        href="https://lifehacker.com/what-could-happen-to-lgbtq-rights-after-a-week-of-polit-1797311310"
        target="_blank"
        rel="noreferrer"
      >
        What Could Happen to LGBTQ Rights After a Week of Political Attacks
      </a>{' '}
      for Lifehacker
    </p>
    <p data-sal="slide-up" data-sal-delay="300" data-sal-easing="ease">
      <a
        href="http://www.huffingtonpost.com/lily-lou/diy-desktop-background-co_b_7904044.html"
        target="_blank"
        rel="noreferrer"
      >
        DIY Desktop Background Collage
      </a>
      &nbsp;for<em>&nbsp;The Huffington Post Teen&nbsp;</em>
    </p>
    <p data-sal="slide-up" data-sal-delay="300" data-sal-easing="ease">
      <a
        href="http://spoonuniversity.com/cook/chipotle-released-official-guacamole-recipe-easier-make-youd-think/?utm_source=huffpost&amp;utm_medium=referral&amp;utm_campaign=content-partnerships"
        target="_blank"
        rel="noreferrer"
      >
        Chipotle Released Their Official Guacamole Recipe, and It&rsquo;s Easier To Make Than
        You&rsquo;d Think
      </a>
      &nbsp;for&nbsp;<em>Spoon University</em>
    </p>
    <p data-sal="slide-up" data-sal-delay="300" data-sal-easing="ease">
      <a
        href="https://lifehacker.com/what-is-a-grand-jury-and-how-does-it-impact-the-russia-1797538788"
        target="_blank"
        rel="noreferrer"
      >
        What Is a Grand Jury and How Does It Impact the Russia Investigation?
      </a>{' '}
      for Lifehacker
    </p>
    <p data-sal="slide-up" data-sal-delay="300" data-sal-easing="ease">
      <a
        href="http://www.guilfordian.com/news/2015/04/10/the-future-of-cce-how-budget-cuts-may-affect-the-program/#sthash.9iYsxQIs.dpuf"
        target="_blank"
        rel="noreferrer"
      >
        The Future of CCE: How Budget Cuts May Affect the Program
      </a>
      &nbsp;for<em> The Guilfordian</em>
    </p>
    <p data-sal="slide-up" data-sal-delay="300" data-sal-easing="ease">
      <a
        href="http://www.rookiemag.com/2015/10/creative-prompt-travel-through-time/4/"
        target="_blank"
        rel="noreferrer"
      >
        Creative Prompt: Tell a Stranger&rsquo;s Backstory
      </a>
      &nbsp;for <em>Rookie Magazine</em>
    </p>
    <br />
    <p data-sal="slide-up" data-sal-delay="300" data-sal-easing="ease">
      For even more, check out <a href="https://lilylou.tumblr.com/writing">my old portfolio</a> or
      my author pages at{' '}
      <a href="https://www.pastemagazine.com/writers?name=lily%20lou">Paste Magazine</a>,{' '}
      <a href="https://lifehacker.com/author/lilylou1">Lifehacker</a>,{' '}
      <a href="https://www.dailytarheel.com/staff/lily-lou">The Daily Tar Heel</a> or{' '}
      <a href="https://www.guilfordian.com/staff_profile/lily-lou/">The Guilfordian</a>.{' '}
    </p>

    <br />
    <br />
    <br />
    <br />
  </div>
);

export default Writing;
